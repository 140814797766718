import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: '寻欢阁',
  description: '寻欢阁 老哥稳 修车大队 一品探花论坛 大圈360 51风流',
  keywords: '寻欢阁 老哥稳 修车大队 一品探花论坛 大圈360 51风流',
}

export default Meta
